<template>
  <div class="google-geocoder fd-validation" :class="{ error: hasError }">
    <slot :searchGeocodes="searchGeocodes"></slot>

    <p v-if="hasError" class="fg-danger">{{ errorMessage }}</p>

    <div class="d-flex">
      <fd-v-select
        v-model="geocode"
        name="geocodeAddrSearch"
        placeholder="Enter Address"
        :options="geocodeOptions"
        :clearSearchOnBlur="() => false"
        :clearSearchOnSelect="false"
        @search="searchGeocodes"
        @selected="geocodeSelected"
        :style="{ flexGrow: 1 }"
      >
        <template
          v-for="slotName in ['option', 'selected-option']"
          #[slotName]="{ formatted_address }"
        >
          <div :key="slotName">
            <div class="font-bold">
              {{ formatted_address }}
            </div>
          </div>
        </template>
      </fd-v-select>
    </div>

    <div v-if="geocode.formatted_address" class="fg-grey-700 my-2">
      {{ geocode.formatted_address }}
      <span v-if="geocode.geometry.location" class="d-block fg-grey-500">
        {{ geocode.geometry.location.lat }}, {{ geocode.geometry.location.lng }}
      </span>
    </div>
  </div>
</template>

<script>
import { getGeocodes } from "@/modules/Address/api/geocode";
import { debounce } from "lodash";
import useValidator from "@/modules/General/composables/useValidator";

export default {
  setup(props) {
    let requiredValidate = (value) => {
      let valid = true;
      if (value.length != 2) {
        valid = false;
      }
      if (value.every((el) => !el)) {
        valid = false;
      }
      let err =
        "Please locate the coordinate with auto-detect or manual input.";
      return valid || err;
    };
    const { validate, hasError, errorMessage } = useValidator(
      props,
      requiredValidate,
      { getValue: (p) => [p.lat, p.lng] }
    );

    return {
      // Validator
      validate,
      hasError,
      errorMessage
    };
  },
  components: {},
  mixins: [],
  props: {
    lat: {
      type: [Number, String],
      default: ""
    },
    lng: {
      type: [Number, String],
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isLoading: false,
      geocode: "",
      oldAddressInput: "",
      geocodeOptions: []
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    geocodeSelected(selectedOption) {
      this.$emit("update:lat", selectedOption.geometry.location.lat);
      this.$emit("update:lng", selectedOption.geometry.location.lng);
    },
    searchGeocodes: debounce(async function (search, loading) {
      if (search == "") {
        return;
      }
      try {
        loading(true);
        if (this.oldAddressInput !== search) {
          this.oldAddressInput = search;

          let data = await getGeocodes(search);
          this.geocodeOptions = data;
        }
        loading(false);
      } catch (error) {
        loading(false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Error occured when searching address. Please try again later."
        });
      }
    }, 500)
  }
};
</script>

<style lang="scss">
.google-geocode {
}
</style>
